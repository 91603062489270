
              @import "@/assets/css/variables.scss";
            


















































































































// @import '@/assets/css/pages/changePassword.scss';
.force_changePassword_wrapper {
  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);

    span {
      font-weight: 400;
      color: $danger;
      @include rtl-sass-prop(margin-right, margin-left, 2px);
    }
  }

  .force_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

}
